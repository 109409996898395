.carousel {
  display: flex;
  height: 400px;
  width: 100%;
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.carousel_card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8); 
  transition: 0.5s ease-in-out;
  right: 0;
  margin: 0 auto;
  top: 45%;
}

.card_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}
  
  .carousel_card-active {
    opacity: 2;
    transform: scale(0.5);
    pointer-events: visible;
  }

  .carousel_pagination {
    position: absolute;
    bottom: -140px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  .pagination_dot {
    height: 10px;
    width: 10px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    display: inline-block;  
    margin-left: 10px;
    cursor: pointer;
  }
  
  .pagination_dot:hover {
    transform: scale(1.2);
  }
  
  .pagination_dot-active {
    background: rgba(255, 255, 255, 1);
    width: 40px;
    border-top-right-radius: 20%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border-top-left-radius: 20%;
  }
  
 /* Responsive styles */
@media (max-width: 1200px) {
  .carousel {
    height: 350px;
  }
  .carousel_card {
    height: 45%;
    transform: scale(0.75);
  }
  .carousel_card-active {
    transform: scale(0.6);
  }
}

@media (max-width: 992px) {
  .carousel {
    height: 300px;
  }
  .carousel_card {
    height: 40%;
    transform: scale(0.7);
  }
  .carousel_card-active {
    transform: scale(0.65);
  }
  .carousel_pagination {
    bottom: -120px;
  }
}

@media (max-width: 768px) {
  .carousel {
    height: 250px;
  }
  .carousel_card {
    height: 35%;
    transform: scale(0.65);
  }
  .carousel_card-active {
    transform: scale(0.7);
  }
  .carousel_pagination {
    bottom: -100px;
  }
}

@media (max-width: 576px) {
  .carousel {
    height: 200px;
  }
  .carousel_card {
    height: 30%;
    transform: scale(0.6);
  }
  .carousel_card-active {
    transform: scale(0.75);
  }
  .carousel_pagination {
    bottom: -80px;
  }
}

@media (min-width: 1600px) {
  .carousel {
    height: 500px;
  }
  .carousel_card {
    height: 60%;
    transform: scale(0.85);
  }
  .carousel_card-active {
    transform: scale(0.55);
  }
  .carousel_pagination {
    bottom: -160px;
  }
}